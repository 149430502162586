import * as bc from "bc-js";

import { GabaritNavigationUtil } from "../../../utils/GabaritNavigationUtil";
import { LangueUtils } from "@oel/js-i18n";
import { en } from "./i18n/DetailsCompteBancaireADA.i18n.en";
import { fr } from "./i18n/DetailsCompteBancaireADA.i18n.fr";
import { useParams } from 'react-router';
import { useEffect } from "react";

export function DetailsCompteBancaireADA() {

    const params = useParams();
    const libelles = new LangueUtils(fr, en).obtenirLibelles();

    useEffect(() => {
        document.title = libelles.titreOnglet;
        GabaritNavigationUtil.afficherPageDetailCompteEOPADA();

        bc.changerContexte("ACCOUNT_Detail");

        return () => {
            GabaritNavigationUtil.retirerAideContact();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <detail-compte-bancaire id-contexte={params.idContexte} />
        </div>
    );
}
